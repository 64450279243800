import React from 'react'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import HomeIcon from "../../assests/Logo/DrawerIcons/HomeIcon.png";
import IdeateIcon from "../../assests/Logo/DrawerIcons/IdeateIcon.png";
import CreateIcon from "../../assests/Logo/DrawerIcons/CreateIcon.png";
import PublishIcon from "../../assests/Logo/DrawerIcons/PublishIcon.png";
import MediaIcon from "../../assests/Logo/DrawerIcons/MediaIcon.png";
import HelpIcon from "../../assests/Logo/DrawerIcons/HelpIcon.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Typography } from "@mui/material";


 

const Index = () => {
  return (
    <div>
         <Box
      className="nav-drawer-child"
      role="presentation"
    >
      
      <List sx={{ p: "13px" }}>
        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={HomeIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Home
          </Typography>
        </ListItem>

        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
            mt:"34px",
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={IdeateIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Ideate
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
            mt:"34px",
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={CreateIcon}
              style={{
                width: "50px",
                height: "50px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Create
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
            mt:"34px",
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={PublishIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Publish
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
            mt:"34px",
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={MediaIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Media
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            flexDirection:"column",
            mt:"34px",
            // "&:hover": {
            //   background: "rgba(255, 255, 255, 0.06)",
            //   borderRadius: "14px",
            // },
          }}
          disablepadding="true"
        >
            <img
              alt="Icon"
              src={HelpIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          <Typography
            sx={{
              color: "#ffffff8c",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            Help
          </Typography>
        </ListItem>
      </List>
      

      <Grid sx={{ position: "absolute", bottom: "15px", left:"15%" }}>
      
       
          <Grid
            sx={{
              height: "51px",
              width: "51px",
              cursor: "pointer",
              background: "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
              border: "0.5px solid #BF9BFF40",
              borderRadius:"9px",
              display:"flex",
              justifyContent:"center", alignItems:"center",
              "&:hover": {
                borderRadius: "8px",
              },
            }}
            disablepadding="true"
          >
            <MoreHorizOutlinedIcon
              sx={{ color: "#ffffff8c", width: "25px", height: "20px" }}
            />
          </Grid> 
      </Grid>
    </Box>
    </div>
  )
}

export default Index
