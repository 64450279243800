import './App.css';
import Home from './pages/home/Index';
import VideoSection from './pages/VideoSection/Index';
import Media from './pages/Media/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
	return (
		<div className="App">
			    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/video" element={<VideoSection />} />
          <Route path="/video/edit/media" element={<Media />} />
        </Routes>
      </BrowserRouter> 
		</div>
	);
}
export default App;