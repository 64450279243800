import React, { useRef, useState } from "react";
import {
    Box,
    TextField,
    Grid,
    RadioGroup,
    Radio,
    InputBase,
    Select,
    FormControl,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Paper,
    Typography,
    Fade,
    Modal,
    Backdrop,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    IconButton,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseIcon from "@mui/icons-material/Pause";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: "54px",
        position: "relative",
        backgroundColor: "#100D2080",
        border: "0.5px solid #BF9BFF40",
        padding: "10px 26px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: "white",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: 400,
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: "54px",
            border: "0.5px solid #BF9BFF40",
            boxShadow: "none",
            backgroundColor: "#100D2080",
        },
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    background: "none",
    boxShadow: "unset",
}));
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#40475A",
                        "--TextField-brandBorderHoverColor": "#B2BAC2",
                        "--TextField-brandBorderFocusedColor": "#6F7E8C",
                        "& label.Mui-focused": {
                            color: "white",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        border: "none",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                        [`& .MuiInputBase-input`]: {
                            color: "white !important",
                            fontSize: "18px",
                            lineHeight: "43px",
                            fontWeight: 500,
                            padding: "0px !important",
                        },
                        [`& ..MuiFormControl-root`]: {
                            width: "75% !important",
                            background: "red",
                        },
                    },
                },
            },
        },
    });
const modalStyle = {
    position: "absolute",
    width: "1059px",
    height: "810px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#110F2D",
    border: "1px solid #DFCDFF1A",
    borderRadius: "20px",
    p: 4,
    overflowY: "scroll",
};
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));
export default function Index({ openYouTube, handleCloseYouTube, formData, updateFormData, submitPropmtFun, language, updateVoiceData, voices, accent, voiceData, Category, bgmusics }) {
    const outerTheme = useTheme();
    const audioPlayer = useRef(null);
    const [audioSrc, setAudioSrc] = useState("");
    const [currentPlaying, setCurrentPlaying] = useState(null);
    const handlePlay = (previewUrl) => {
        if (audioPlayer.current) {
            if (audioSrc === previewUrl && !audioPlayer.current.paused) {
                audioPlayer.current.pause();
                setCurrentPlaying(null);
            } else {
                if (audioPlayer.current) {
                    audioPlayer.current.pause();
                    audioPlayer.current.currentTime = 0;
                }
                setAudioSrc(previewUrl);
                audioPlayer.current.src = previewUrl;
                audioPlayer.current.play();
                setCurrentPlaying(previewUrl);
                audioPlayer.current.onended = () => {
                    setCurrentPlaying(null);
                };
            }
        }
    };
    return (
        <div>
            <Modal
                className="youtube-pop-up"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openYouTube}
                onClose={handleCloseYouTube}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openYouTube}>
                    <Box sx={modalStyle}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Grid sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <IconButton>
                                    <YouTubeIcon sx={{ fontSize: "45px", color: "white" }} />
                                </IconButton>
                                <Typography
                                    id="transition-modal-title"
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: 500,
                                        lineHeight: "31px",
                                        color: "white",
                                    }}
                                    variant="h6"
                                    component="h2"
                                >
                                    Youtube Video
                                </Typography>
                            </Grid>

                            <Grid
                                onClick={handleCloseYouTube}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    background: "#201A46",
                                    border: "0.5px solid #BF9BFF40",
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                }}
                            >
                                <CloseIcon sx={{ color: "white", fontSize: "20px" }} />
                            </Grid>
                        </Box>

                        <Box className="youtube-accordion-parent">
                            <Accordion defaultExpanded className="youtube-accordion">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            lineHeight: "31px",
                                            color: "white",
                                        }}
                                        variant="h6"
                                    >
                                        Basic Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid sx={{ alignItems: "center" }} container spacing={0}>
                                        <Grid xs={6}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Length of video
                                                </Typography>

                                                <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                                                    <Select
                                                        className="duration-menu"
                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        value={formData.duration}
                                                        placeholder="Select video duration"
                                                        onChange={(e) => updateFormData("duration", e.target.value)}
                                                        input={<BootstrapInput />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                    color: "white",
                                                                    boxShadow: "0px 4px 4px 0px #00000040",
                                                                    borderRadius: "20px",
                                                                    marginTop: "5px",
                                                                    "& .MuiMenuItem-root": {
                                                                        bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                        color: "white",
                                                                        boxShadow: "0px 4px 4px 0px #00000040",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        "&:hover": {
                                                                            bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={0} disabled>
                                                            Select video duration
                                                        </MenuItem>
                                                        <MenuItem value={15}>15 Seconds</MenuItem>
                                                        <MenuItem value={30}>30 Seconds</MenuItem>
                                                        <MenuItem value={60}>1 Minute </MenuItem>
                                                        <MenuItem value={120}>2 Minute</MenuItem>
                                                        <MenuItem value={180}>3 Minute</MenuItem>
                                                        <MenuItem value={300}>5 Minute</MenuItem>
                                                        <MenuItem value={360}>6 Minute</MenuItem>
                                                        <MenuItem value={480}>8 Minute </MenuItem>
                                                        <MenuItem value={600}>10 Minutes</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Item>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Language
                                                </Typography>
                                                <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                                                    <Select
                                                        className="duration-menu"
                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            const selectedName = language[selectedValue];
                                                            updateFormData("language", selectedName);
                                                            updateVoiceData("language", e.target.value);
                                                        }}
                                                        value={voiceData.language}
                                                        input={<BootstrapInput />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                    color: "white",
                                                                    boxShadow: "0px 4px 4px 0px #00000040",
                                                                    borderRadius: "20px",
                                                                    marginTop: "5px",
                                                                    "& .MuiMenuItem-root": {
                                                                        bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                        color: "white",
                                                                        boxShadow: "0px 4px 4px 0px #00000040",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        "&:hover": {
                                                                            bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {/* <MenuItem value=" ">Select Language</MenuItem> */}
                                                        {Object.entries(language).map(([code, name]) => (
                                                            <MenuItem key={code} value={code}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ mt: "25px" }}>
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: 500,
                                                lineHeight: "26px",
                                                color: "white",
                                            }}
                                            variant="h6"
                                        >
                                            Topic
                                        </Typography>
                                        <textarea
                                            style={{
                                                borderRadius: "18px",
                                                padding: "16px 27px",
                                                marginTop: "12px",
                                            }}
                                            className="text-area-popup"
                                            autosize
                                            placeholder="I want a Mars rover flying in the air and, there should be a robot looking at the rover and waving hands..."
                                            onChange={(e) => updateFormData("prompt", e.target.value)}
                                        ></textarea>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            marginTop: "34px",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "14px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: 500,
                                                lineHeight: "26px",
                                                color: "white",
                                            }}
                                            variant="h6"
                                        >
                                            Background music
                                        </Typography>
                                        {/* <Item
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                border: "0.5px solid #BF9BFF40",
                                                p: "13px 24px",
                                                background: "#100D2080",
                                                width: "75%",
                                                borderRadius: "164px",
                                            }}
                                        >
                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    placeholder="Dark and haunting, upbeat and happy etc..."
                                                    onChange={(e) => updateFormData("background_music", e.target.value)}
                                                />
                                            </ThemeProvider>
                                        </Item> */}
                                        <FormControl sx={{ width: "70%" }} variant="standard" className="youtube-dropdown-custom">
                                            <Select
                                                className="duration-menu"
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                value={formData?.background_music}
                                                onChange={(e) => {
                                                    updateFormData("background_music", e.target.value);
                                                }}
                                                label="Select Accent"
                                                input={<BootstrapInput />}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: "rgba(16, 13, 32, 0.7)",
                                                            color: "white",
                                                            boxShadow: "0px 4px 4px 0px #00000040",
                                                            borderRadius: "20px",
                                                            marginTop: "5px",
                                                            "& .MuiMenuItem-root": {
                                                                bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                color: "white",
                                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                "&:hover": {
                                                                    bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Background Music
                                                </MenuItem>
                                                {bgmusics.map((voice, index) => (
                                                    <MenuItem key={index} value={voice.file}>
                                                        {voice.name}

                                                        <button
                                                            style={{
                                                                marginLeft: "10px",
                                                                cursor: "pointer",
                                                                background: "none",
                                                                border: "none",
                                                                color: "#fff",
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent triggering the menu select
                                                                handlePlay(voice.file);
                                                            }}
                                                        >
                                                            {currentPlaying === voice.file ? <PauseIcon /> : <PlayCircleFilledWhiteOutlinedIcon />}
                                                        </button>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion className="youtube-accordion" style={{ marginTop: "12px" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel12-header">
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            lineHeight: "31px",
                                            color: "white",
                                        }}
                                        variant="h6"
                                    >
                                        Audience Engagement Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={0}>
                                        <Grid xs={7}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Hook
                                                </Typography>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit"> hooks can depending on target audience, context, and desired outcome. they can be</Typography>
                                                            {
                                                                "Question Hooks, Personal Address, Catchphrases , Relatable Statements ,Storytelling ,Humor ,Teasers, Statistics or Facts,Emotional Appeal ,Urgency Hooks ,Repetition"
                                                            }
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <InfoOutlinedIcon sx={{ color: "#f0f8ff" }} />
                                                </HtmlTooltip>

                                                {/* <Item
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "0.5px solid #BF9BFF40",
                                                        p: "13px 24px",
                                                        background: "#100D2080",
                                                        width: "75%",
                                                        borderRadius: "164px",
                                                    }}
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField sx={{ width: "100%" }} placeholder="Compelling fact or statistic" onChange={(e) => updateFormData("hooks", e.target.value)} />
                                                    </ThemeProvider>
                                                </Item> */}
                                                <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                                                    <Select
                                                        className="duration-menu"
                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        value={formData.hooks}
                                                        placeholder="Select video duration"
                                                        onChange={(e) => {
                                                            updateFormData("hooks", e.target.value);
                                                            updateVoiceData("use_cases", e.target.value);
                                                        }}
                                                        input={<BootstrapInput />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                    color: "white",
                                                                    boxShadow: "0px 4px 4px 0px #00000040",
                                                                    borderRadius: "20px",
                                                                    marginTop: "5px",
                                                                    "& .MuiMenuItem-root": {
                                                                        bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                        color: "white",
                                                                        boxShadow: "0px 4px 4px 0px #00000040",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        "&:hover": {
                                                                            bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={0} disabled>
                                                            Select Hook
                                                        </MenuItem>
                                                        {Object.entries(Category).map(([code, name]) => (
                                                            <MenuItem key={code} value={code}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Item>
                                        </Grid>
                                        <Grid xs={5}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Audience
                                                </Typography>
                                                <Item
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "0.5px solid #BF9BFF40",
                                                        p: "13px 24px",
                                                        background: "#100D2080",
                                                        borderRadius: "164px",
                                                    }}
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField sx={{ width: "100%" }} placeholder="Who is this for?" onChange={(e) => updateFormData("target_audience", e.target.value)} />
                                                    </ThemeProvider>
                                                </Item>
                                            </Item>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ mt: "25px" }} container spacing={0}>
                                        <Grid xs={6}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Tone
                                                </Typography>
                                                <Item
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "0.5px solid #BF9BFF40",
                                                        p: "13px 24px",
                                                        background: "#100D2080",
                                                        width: "75%",
                                                        borderRadius: "164px",
                                                    }}
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            sx={{ width: "100%" }}
                                                            placeholder="Informative, thought-provoking, entertaining"
                                                            onChange={(e) => updateFormData("tone", e.target.value)}
                                                        />
                                                    </ThemeProvider>
                                                </Item>
                                            </Item>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Call-to-action
                                                </Typography>
                                                <Item
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "0.5px solid #BF9BFF40",
                                                        p: "13px 24px",
                                                        background: "#100D2080",
                                                        borderRadius: "164px",
                                                    }}
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField sx={{ width: "100%" }} placeholder="Subscribe to my channel" onChange={(e) => updateFormData("call_to_action", e.target.value)} />
                                                    </ThemeProvider>
                                                </Item>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion className="youtube-accordion" style={{ marginTop: "12px" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel13-header">
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            lineHeight: "31px",
                                            color: "white",
                                        }}
                                        variant="h6"
                                    >
                                        Audience Engagement Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid sx={{ alignItems: "center" }} container spacing={0}>
                                        <Grid xs={5}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Voice
                                                </Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        className="youtubeRadioBtn"
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        sx={{ display: "flex", flexDirection: "row", gap: "7px" }}
                                                        // onChange={handleCheckRadio}
                                                        onChange={(e) => {
                                                            updateFormData("voice", e.target.value);
                                                            updateVoiceData("gender", e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            className="femaleBtn"
                                                            value="female"
                                                            control={<Radio sx={{ color: "#763CDE" }} />}
                                                            label="Female"
                                                            sx={{
                                                                color: "rgba(255, 255, 255, 0.6)",
                                                                border: "1px solid #763CDE",
                                                                padding: "10px 15px",
                                                                borderRadius: "54px",
                                                                background: formData.voice === "female" ? "#261e5b" : "#100D2080",
                                                            }}
                                                        />
                                                        <FormControlLabel
                                                            className="maleBtn"
                                                            value="male"
                                                            control={<Radio sx={{ color: "#763CDE" }} />}
                                                            label="Male"
                                                            sx={{
                                                                color: "rgba(255, 255, 255, 0.6)",
                                                                border: "1px solid #763CDE",
                                                                padding: "10px 15px",
                                                                borderRadius: "54px",
                                                                background: formData.voice === "male" ? "#261e5b" : "#100D2080",
                                                            }}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Item>
                                        </Grid>
                                        <Grid xs={7}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "25px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    }}
                                                    variant="h6"
                                                >
                                                    Accent
                                                </Typography>

                                                {/* <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom"> */}
                                                {/* <Select
                                                        className="duration-menu"
                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        value={voiceData?.accent}
                                                        placeholder="Select Accent"
                                                        onChange={(e) => updateVoiceData("accent", e.target.value)}
                                                        input={<BootstrapInput />}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                    color: "white",
                                                                    boxShadow: "0px 4px 4px 0px #00000040",
                                                                    borderRadius: "20px",
                                                                    marginTop: "5px",
                                                                    "& .MuiMenuItem-root": {
                                                                        bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                        color: "white",
                                                                        boxShadow: "0px 4px 4px 0px #00000040",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        "&:hover": {
                                                                            bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={0} disabled>
                                                            Select Accent
                                                        </MenuItem>
                                                        {accent.map((item, index) => (
                                                            <MenuItem key={index} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select> */}

                                                {/* </FormControl> */}
                                                <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                                                    <Autocomplete
                                                        disablePortal
                                                        options={accent}
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiOutlinedInput-root": {
                                                                bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                color: "white",
                                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                                borderRadius: "20px",
                                                                "& fieldset": {
                                                                    borderColor: "transparent", // Remove border
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "rgba(44, 34, 102, 0.75)", // Hover effect for border
                                                                },
                                                                "& .MuiSvgIcon-root": {
                                                                    color: "white", // Dropdown icon color
                                                                },
                                                            },
                                                            "& .MuiAutocomplete-option": {
                                                                bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                color: "white",
                                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                "&:hover": {
                                                                    bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                },
                                                            },
                                                        }}
                                                        getOptionLabel={(option) => option || "Select Accent"}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="Select Accent"
                                                                InputLabelProps={{
                                                                    style: { color: "white" }, // Custom label color
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    sx: {
                                                                        color: "white", // Input text color
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                        value={voiceData?.accent}
                                                        onChange={(event, newValue) => updateVoiceData("accent", newValue)}
                                                    />
                                                </FormControl>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <audio ref={audioPlayer} controls style={{ display: "none", marginBottom: "10px" }}>
                                            <source src={audioSrc} type="audio/mpeg" />
                                            this is browser not supported
                                        </audio>
                                        {voices.length < 1 && (
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    // fontWeight: 500,
                                                    lineHeight: "26px",
                                                    color: "red",
                                                }}
                                                variant="h6"
                                            >
                                                No Voice Available
                                            </Typography>
                                        )}
                                        <Item
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "25px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 500,
                                                    lineHeight: "26px",
                                                    color: "white",
                                                }}
                                                variant="h6"
                                            >
                                                Available Voices
                                            </Typography>
                                            <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                                                <Select
                                                    className="duration-menu"
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={formData?.voice_id}
                                                    onChange={(e) => {
                                                        const selectedVoice = voices.find((voice) => voice.voice_id === e.target.value);
                                                        if (selectedVoice) {
                                                            updateFormData("voice_id", selectedVoice.voice_id);
                                                            updateFormData("public_owner_id", selectedVoice.public_owner_id);
                                                            updateFormData("name", selectedVoice.name);
                                                        }
                                                    }}
                                                    label="Select Accent"
                                                    input={<BootstrapInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                color: "white",
                                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                "& .MuiMenuItem-root": {
                                                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                                                    color: "white",
                                                                    boxShadow: "0px 4px 4px 0px #00000040",
                                                                    borderRadius: "20px",
                                                                    marginTop: "5px",
                                                                    "&:hover": {
                                                                        bgcolor: "rgba(44, 34, 102, 0.75)",
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={0} disabled>
                                                        Select Voice
                                                    </MenuItem>
                                                    {voices.map((voice, index) => (
                                                        <MenuItem key={index} value={voice.voice_id}>
                                                            {voice.name}

                                                            <button
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    cursor: "pointer",
                                                                    background: "none",
                                                                    border: "none",
                                                                    color: "#fff",
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Prevent triggering the menu select
                                                                    handlePlay(voice.preview_url);
                                                                }}
                                                            >
                                                                {currentPlaying === voice.preview_url ? <PauseIcon /> : <PlayCircleFilledWhiteOutlinedIcon />}
                                                            </button>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Item>
                                    </Grid>

                                    <Grid
                                        sx={{
                                            marginTop: "34px",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "14px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: 500,
                                                lineHeight: "26px",
                                                color: "white",
                                            }}
                                            variant="h6"
                                        >
                                            Watermark text
                                        </Typography>
                                        <Item
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                border: "0.5px solid #BF9BFF40",
                                                p: "13px 24px",
                                                background: "#100D2080",
                                                width: "75%",
                                                borderRadius: "164px",
                                            }}
                                        >
                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                                <TextField sx={{ width: "100%" }} placeholder="Type your text here..." onChange={(e) => updateFormData("watermark_text", e.target.value)} />
                                            </ThemeProvider>
                                        </Item>
                                        {/* <Item>
                                            <LogoutIcon
                                                sx={{
                                                    color: "white",
                                                    fontSize: "24px",
                                                    transform: "rotate(-90deg) !important",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Item> */}
                                    </Grid>

                                    <Grid
                                        sx={{
                                            marginTop: "34px",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "14px",
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                className="class-color"
                                                style={{ margin: "0px" }}
                                                control={<Checkbox defaultChecked />}
                                                label="Include Generative Engine Optimization (GEO) content"
                                                sx={{
                                                    "& .MuiFormControlLabel-label": {
                                                        fontSize: "20px",
                                                        fontWeight: 500,
                                                        lineHeight: "26px",
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                        <Item>
                                            <ErrorOutlineIcon
                                                sx={{
                                                    color: "white",
                                                    fontSize: "20px",
                                                    opacity: "30%",
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Item sx={{ display: "flex", justifyContent: "end", mt: "22px" }}>
                                <Button
                                    sx={{
                                        background: "#763CDE",
                                        fontSize: "18px",
                                        lineHeight: "23px",
                                        fontWeight: 700,
                                        padding: "15px 28px",
                                        borderRadius: "9px",
                                        boxShadow: "0px -7px 16.8px 0px #3C2496 inset",
                                        color: "#ffffff",
                                        textTransform: "none",
                                        "&:hover": {
                                            background: "#763CDE",
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={() => submitPropmtFun()}
                                >
                                    Continue <ArrowRightAltIcon sx={{ fontSize: "25px", color: "white", ml: "14px" }} />
                                </Button>
                            </Item>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
