import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Container, Snackbar, Backdrop } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, scopedCssBaselineClasses, Typography, useMediaQuery } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddIcon from "@mui/icons-material/Add";
import Advertisement from "./Advertisement";
import Explore from "./Explore";
import Textarea from "./Textarea";
import PromptIdeas from "./PromptIdeas";
import Navbar from "../../layouts/navbar/Index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Youtubevideo from "../../Popups/YoutubeVideo/Index";
import YoutubeShorts from "../../Popups/YoutubeShorts/Index";

const Item = styled(Paper)(({ theme }) => ({
    background: "none",
    boxShadow: "unset",
}));
const language = {
    zh: "Chinese",
    ko: "Korean",
    nl: "Dutch",
    tr: "Turkish",
    sv: "Swedish",
    id: "Indonesian",
    fil: "Filipino",
    ja: "Japanese",
    uk: "Ukrainian",
    el: "Greek",
    cs: "Czech",
    fi: "Finnish",
    ro: "Romanian",
    ru: "Russian",
    da: "Danish",
    bg: "Bulgarian",
    ms: "Malay",
    sk: "Slovak",
    hr: "Croatian",
    ar: "Classic Arabic",
    ta: "Tamil",
    en: "English",
    pl: "Polish",
    de: "German",
    es: "Spanish",
    fr: "French",
    it: "Italian",
    hi: "Hindi",
    pt: "Portuguese",
};
const Category = {
    " ": "All",
    conversational: "Conversational",
    narrative_story: "Narrative & Story",
    characters_animation: "Characters & Animation",
    social_media: "Social Media",
    entertainment_tv: "Entertainment & TV",
    advertisement: "Advertisement",
    informative_educational: "Informative & Educational",
};

export default function Index() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:899px)");
    const [showExplore, setShowExplore] = useState(false);
    const [textEditorVavlue, setTextEditorVavlue] = useState(null);
    const [apiloader, setApiLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setMsg] = useState(null);
    const [openYouTube, setOpenYouTube] = useState(false);
    const [openYouTubeShorts, setOpenYouTubeShorts] = useState(false);
    const [openTiktokVideo, setOpenTiktokVideo] = useState(false);
    const [openInstagramReels, setOpenInstagramReels] = useState(false);
    const [bgmusics, setBGMusics] = useState();
    const [videoType, setVideoType] = useState("yt");
    const [formData, setFormData] = useState({
        video_type: videoType,
        duration: 0,
        pace: 1,
        language: "english",
        prompt: "",
        background_music: "",
        hooks: 0,
        target_audience: "",
        tone: "",
        call_to_action: "",
        voice: "female",
        watermark_text: "",
        voice_id: 0,
        accent: 0,
        public_owner_id: "",
        name: "",
    });

    const [voiceData, setVoiceData] = useState({
        page_size: 100,
        category: "professional",
        gender: "female",
        age: "young",
        language: "en",
        accent: 0,
        use_cases: "",
        sort: "trending",
    });

    const [voices, setVoices] = useState([]);
    const [accent, setAccent] = useState([]);
    const updateVoiceData = (key, value) => {
        if (key === "language") {
            setVoiceData((prev) => ({ ...prev, accent: 0 }));
        }
        setVoiceData((prev) => ({ ...prev, [key]: value }));
    };
    const resetFormData = () => {
        setFormData({
            video_type: videoType,
            duration: 0,
            pace: 1,
            language: "english",
            prompt: "",
            background_music: "",
            hooks: 0,
            target_audience: "",
            tone: "",
            call_to_action: "",
            voice: "female",
            watermark_text: "",
            voice_id: 0,
            accent: 0,
            public_owner_id: "",
            name: "",
        });
    };
    const resetVoiceData = () => {
        setVoiceData({
            page_size: 100,
            category: "professional",
            gender: "female",
            age: "young",
            language: "en",
            accent: 0,
            use_cases: "",
            sort: "trending",
        });
    };

    const updateFormData = (key, value) => {
        if (key === "language") {
            setFormData((prev) => ({ ...prev, voice_id: 0 }));
        }
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    const handleOpenInstagramReels = () => {
        setVideoType("insta");
        resetFormData();
        resetVoiceData();
        setOpenInstagramReels(!openInstagramReels);
        updateFormData("duration", 30);
        updateFormData("video_type", "insta");
    };
    const handleOpenYouTube = () => {
        setVideoType("yt");
        resetFormData();
        resetVoiceData();
        setOpenYouTube(!openYouTube);
    };
    const handleOpenTiktokVideo = () => {
        setVideoType("tiktok");
        resetFormData();
        resetVoiceData();
        setOpenTiktokVideo(!openTiktokVideo);
        updateFormData("duration", 30);
        updateFormData("video_type", "tiktok");
    };
    const handleOpenYouTubeShorts = () => {
        setVideoType("ytshort");
        resetFormData();
        resetVoiceData();
        setOpenYouTubeShorts(!openYouTubeShorts);
        updateFormData("duration", 30);
        updateFormData("video_type", "ytshort");
    };

    const handleToggle = () => {
        setShowExplore(!showExplore);
    };

    const handleClose = () => {
        setShowExplore(false);
    };
    const [showTextarea, setShowTextarea] = useState(false);

    const handleToggleTextarea = () => {
        setShowTextarea(true);
    };

    const handleClosePromptIdeas = () => {
        setShowTextarea(false);
    };

    const handleTextEditor = (event) => {
        // setTextEditorVavlue(event.target.value);
        setFormData((prev) => ({ ...prev, prompt: event.target.value }));
    };

    const showErrorNotification = () => {
        setState({ vertical: "top", horizontal: "center", open: true });
        setTimeout(() => {
            setState({ ...state, open: false });
        }, 5000);
    };

    const directGenerateContent = () => {
        setFormData((prev) => ({ ...prev, voice_id: "Xb7hH8MSUJpSbSDYk0k2", duration: 100, video_type: "yt", watermark_text: "Pixel Genie" }));
        setVideoType("yt");
        submitPropmtFun();
    };

    const submitPropmtFun = async () => {
        const trimmedPrompt = formData.prompt?.trim();

        if (!trimmedPrompt || trimmedPrompt === "") {
            setErrorMsg("Prompt is not available");
            showErrorNotification();
            return;
        }

        if (formData.voice_id === 0) {
            setErrorMsg("Please select a voice.");
            showErrorNotification();
            return;
        }

        if (formData.duration === 0) {
            setErrorMsg("Please select a duration.");
            showErrorNotification();
            return;
        }

        try {
            setApiLoader(true);
            await textToVideos(formData);
            // return false;
        } catch (e) {
            console.log(e, "error");
        } finally {
            // resetFormData();
        }
    };

    const textToVideos = async (data) => {
        let allVideos;
        let videoRatio;
        let videoId;
        let tryAgain = false;

        try {
            //await axios.post(process.env.REACT_APP_APIURL+'/api/text-to-pixabay-video', {
            await axios
                .post(process.env.REACT_APP_APIURL + "text-to-video", {
                    prompt: data.prompt,
                    data: data,
                })
                .then(async function (response) {
                    if (response.status === 200 && response.data.status !== "error") {
                        setMsg(response.data.message);
                        allVideos = response.data.data;
                        videoRatio = response.data.video_ratio;
                        videoId = response.data.video_id;
                    } else {
                        setMsg(response.data.message);
                        setApiLoader(false);
                    }
                })
                .catch(function (error) {
                    setApiLoader(false);
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }

        // if(tryAgain){
        //     setMsg('Try again');
        //     setApiLoader(false);
        // }
        // }
        if (allVideos.length > 0) {
            analyzeVideo(allVideos, formData, videoRatio, videoId);
        } else {
            setApiLoader(false);
            setErrorMsg(" Video not available for this Propmt ".myString);
            setState({ vertical: "top", horizontal: "center", open: true });
            setTimeout(function () {
                setState({ ...state, open: false });
            }, 5000);
        }
    };

    const analyzeVideo = async (data, fmdata, videoRatio, videoId) => {
        axios
            .post(process.env.REACT_APP_APIURL + "analyze-video", {
                prompt: fmdata.prompt,
                video_urls: data,
                video_ratio: videoRatio,
                video_id: videoId,
            })
            .then(function (response) {
                console.log("res 2===", response);
                if (response.status === 200 && response.data.status !== "error") {
                    setMsg(response.data.message);
                    generateScript(response, videoId);
                } else {
                    setErrorMsg(response.data.message);
                    setApiLoader(false);
                    setState({ vertical: "top", horizontal: "center", open: true });
                    setTimeout(function () {
                        setState({ ...state, open: false });
                    }, 8000);
                }
            });
    };

    const generateScript = async (responseData, videoId) => {
        axios
            .post(process.env.REACT_APP_APIURL + "generate-script", {
                prompt: responseData.data.topic,
                public_owner_id: formData.public_owner_id,
                voice_id: formData.voice_id,
                name: formData.name,
                downloadPaths: responseData.data.data,
                video_id: videoId,
                data: formData,
            })
            .then(function (response) {
                console.log("res 3===", response);
                if (response.status === 200 && response.data.status !== "error") {
                    setMsg(response.data.message);
                    videoProcessing(response);
                } else {
                    setErrorMsg(response.data.message);
                    setApiLoader(false);
                    setState({ vertical: "top", horizontal: "center", open: true });
                    setTimeout(function () {
                        setState({ ...state, open: false });
                    }, 8000);
                }
            });
    };

    const videoProcessing = async (scriptData) => {
        axios
            .post(process.env.REACT_APP_APIURL + "video-processing", {
                video_id: scriptData.data.video_id,
                data: scriptData.data.data,
            })
            .then(function (response) {
                console.log("res 4===", response);
                if (response.status === 200 && response.data.status !== "error") {
                    setMsg(response.data.message);
                    videoRendering(response);
                } else {
                    setErrorMsg(response.data.message);
                    setApiLoader(false);
                    setState({ vertical: "top", horizontal: "center", open: true });
                    setTimeout(function () {
                        setState({ ...state, open: false });
                    }, 8000);
                }
            });
    };

    const videoRendering = async (videoData) => {
        axios
            .post(process.env.REACT_APP_APIURL + "video-rendering", {
                source: videoData.data.data,
                captions: videoData.data.captions,
                video_id: videoData.data.video_id,
                background_music: formData.background_music,
                watermark_text: formData.watermark_text,
            })
            .then(function (response) {
                console.log("res 5===", response.data.data[0]);
                if (response.status === 200 && response.data.status !== "error") {
                    setMsg(response.data.message);
                    navigate("/video?token="+btoa(videoData.data.video_id), { state: { data: response.data.data[0], video_id: videoData.data.video_id, formData: formData } });
                    setApiLoader(false);
                } else {
                    setMsg("Try again");
                    setErrorMsg(response.data.message);
                    setApiLoader(false);
                    setState({ vertical: "top", horizontal: "center", open: true });
                    //setTimeout(function () {
                    setState({ ...state, open: false });
                    //},8000); 
                }
            });
    };

    const [state, setState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
    });
    const { vertical, horizontal, open } = state;

    const filterVoiceData = (data) => {
        return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== "" && value !== null && value !== undefined && value !== 0 && value !== " "));
    };
    const getVoices = async () => {
        const filteredData = filterVoiceData(voiceData);

        try {
            await axios
                .post(process.env.REACT_APP_APIURL + "voices", filteredData)
                .then(async function (response) {
                    if (response.status === 200 && response.data.status !== "error") {
                        if (response?.data?.data) {
                            setVoices(response?.data?.data);
                            if (!filteredData?.accent) {
                                setAccent([...new Set(response?.data?.data.filter((item) => item.accent && item.accent.trim() !== "").map((item) => item.accent))]);
                            }
                        }
                    } else {
                        setVoices([]);
                        setAccent([]);
                    }
                })
                .catch(function (error) {
                    setApiLoader(false);
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getBackgroundMusic = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_APIURL + "bg-music-list")
                .then(async function (response) {
                    if (response.status === 200 && response.data.status !== "error") {
                        if (response?.data?.data) {
                            setBGMusics(response?.data?.data);
                        }
                    } else {
                        setBGMusics([]);
                    }
                })
                .catch(function (error) {
                    setApiLoader(false);
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getBackgroundMusic();
    }, []);

    useEffect(() => {
        getVoices();
    }, [voiceData]);

    return (
        <Box>
            <Navbar />
            <Box sx={{ flexGrow: 1, mt: "41px" }}>
                <Container maxWidth="xl" sx={{ maxWidth: "1760px !important" }}>
                    <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleClose} message={errorMsg} key={vertical + horizontal} />
                    <Backdrop
                        sx={{ color: "#fff", zIndex: 9999 }}
                        open={apiloader}
                        //onClick={handleClose}
                    >
                        {successMsg === null ? (!apiloader ? "Generate Content" : "Generating...") : successMsg}
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={0}>
                        <Grid sx={{ px: "40px" }} xs={8}>
                            <Item>
                                <Item sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: "62px",
                                            fontWeight: 700,
                                            lineHeight: "73px",
                                            color: "white",
                                        }}
                                    >
                                        Hello,
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: "62px",
                                            fontWeight: 700,
                                            lineHeight: "73px",
                                            background: "linear-gradient(95.83deg, #FFB500 23.09%, #9459FE 33.18%, #3F2DB0 40.94%)",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                        }}
                                    >
                                        Alex!
                                    </Typography>
                                </Item>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: "62px",
                                        fontWeight: 700,
                                        lineHeight: "73px",
                                        color: "white",
                                    }}
                                >
                                    How can I help you today?
                                </Typography>
                                {apiloader && (
                                    <div className="overlay">
                                        <div className="overlay__inner">
                                            <div className="overlay__content">
                                                <span className="spinner"></span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Item sx={{ mt: "27px" }}>
                                    {showTextarea ? (
                                        <PromptIdeas onClose={handleClosePromptIdeas} />
                                    ) : (
                                        <Textarea disabled={!apiloader} textEditorVavlue={textEditorVavlue} handleTextEditor={handleTextEditor} />
                                    )}
                                </Item>
                                <Grid
                                    sx={{
                                        mt: "32px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Item>
                                        {" "}
                                        <Button
                                            sx={{
                                                display: "flex",
                                                gap: "4px",
                                                background: "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
                                                padding: "14px 20px",
                                                borderRadius: "25px",
                                                border: "0.5px solid #BF9BFF40x",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 700,
                                                "&:hover": {
                                                    background: "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={handleToggleTextarea}
                                        >
                                            <AutoFixHighIcon sx={{ width: "20px", height: "20px" }} />
                                            Click for prompt ideas{" "}
                                        </Button>
                                    </Item>
                                    <Item>
                                        <Button
                                            className="nav-button"
                                            sx={{
                                                display: "flex",
                                                gap: "4px",
                                                padding: "13px 32px",
                                                borderRadius: "100px",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 700,
                                                boxShadow: "0px -7px 16.8px 0px #B04204 inset",
                                            }}
                                            variant="contained"
                                            onClick={!apiloader ? directGenerateContent : undefined}
                                        >
                                            {successMsg === null ? (!apiloader ? "Generate Content" : "Generating...") : successMsg}
                                            <AutoAwesomeIcon sx={{ width: "20px", height: "20px" }} />
                                        </Button>
                                    </Item>
                                </Grid>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        background: "#100D20",
                                        borderRadius: "12px",
                                        padding: "16px 20px",
                                        mt: "46px",
                                    }}
                                >
                                    <Grid sx={{}}>
                                        <Item>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    lineHeight: "26px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                    textAlign: isMobile ? "center" : "",
                                                }}
                                            >
                                                I want to create:
                                            </Typography>
                                        </Item>
                                    </Grid>

                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            flexDirection: isMobile ? "column" : "row",
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            onClick={() => handleOpenYouTube()}
                                            variant="outlined"
                                        >
                                            YouTube Video
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            Script to Video
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                            onClick={() => handleOpenYouTubeShorts()}
                                        >
                                            YouTube Shorts
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            New Presentation{" "}
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#bf9bff40",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "white",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                            onClick={handleToggle}
                                        >
                                            Explore all <AddIcon sx={{ height: "20px", width: "20px" }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid xs={4}>
                            {showExplore ? (
                                <Explore
                                    handleClose={handleClose}
                                    openYouTube={openYouTube}
                                    handleCloseYouTube={handleOpenYouTube}
                                    openYouTubeShorts={openYouTubeShorts}
                                    handleCloseYouTubeShorts={handleOpenYouTubeShorts}
                                    openTiktokVideo={openTiktokVideo}
                                    handleCloseTiktokVideo={handleOpenTiktokVideo}
                                    openInstagramReels={openInstagramReels}
                                    handleCloseInstagramReels={handleOpenInstagramReels}
                                    formData={formData}
                                    updateFormData={updateFormData}
                                    submitPropmtFun={submitPropmtFun}
                                    language={language}
                                    updateVoiceData={updateVoiceData}
                                    voices={voices}
                                    accent={accent}
                                    voiceData={voiceData}
                                    Category={Category}
                                    bgmusics={bgmusics}
                                />
                            ) : (
                                <Advertisement />
                            )}
                        </Grid>
                    </Grid>
                </Container>
                {openYouTube && (
                    <Youtubevideo
                        handleCloseYouTube={handleOpenYouTube}
                        openYouTube={openYouTube}
                        formData={formData}
                        updateFormData={updateFormData}
                        submitPropmtFun={submitPropmtFun}
                        language={language}
                        updateVoiceData={updateVoiceData}
                        voices={voices}
                        accent={accent}
                        voiceData={voiceData}
                        Category={Category}
                        bgmusics={bgmusics}
                    />
                )}
                {openYouTubeShorts && (
                    <YoutubeShorts
                        handleCloseYouTubeShorts={handleOpenYouTubeShorts}
                        openYouTubeShorts={openYouTubeShorts}
                        formData={formData}
                        updateFormData={updateFormData}
                        submitPropmtFun={submitPropmtFun}
                        language={language}
                        updateVoiceData={updateVoiceData}
                        voices={voices}
                        accent={accent}
                        voiceData={voiceData}
                        Category={Category}
                        bgmusics={bgmusics}
                    />
                )}
            </Box>
        </Box>
    );
}
