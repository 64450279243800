import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../layouts/navbar/Index";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CachedIcon from "@mui/icons-material/Cached";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useLocation } from "react-router-dom";
import axios from "axios";

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#40475A",
                        "--TextField-brandBorderHoverColor": "#B2BAC2",
                        "--TextField-brandBorderFocusedColor": "#6F7E8C",
                        "& label.Mui-focused": {
                            color: "white",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        border: "none",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                        [`& .MuiInputBase-input`]: {
                            color: "white !important",
                            fontSize: "18px",
                            lineHeight: "43px",
                            fontWeight: 500,
                            padding: "0px !important",
                        },
                        [`& ..MuiFormControl-root`]: {
                            width: "75% !important",
                            background: "red",
                        },
                    },
                },
            },
        },
    });

const Item = styled(Paper)(({ theme }) => ({
    background: "none",
    boxShadow: "unset",
}));

const Index = () => {
    const navigate=useNavigate();
    const outerTheme = useTheme();
    const location = useLocation();
    const [currentVideo, setCurrentVideo] = useState("");
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const videoRef = useRef(null);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token'); // video_id
    const video_id = atob(token);
    const { state } = location;
    //console.log("video url: " + state?.data);
    const responseData = state?.data ? state.data : "";
    const VideoId =state?.video_id ? state.video_id : video_id;
    const formData =state?.formData ? state.formData : {};

    useEffect(() => {
        if (video_id) {
            try {
                axios.post(process.env.REACT_APP_APIURL + "get-video", {
                    id: video_id,
                })
                .then(async (response) => {
                    if (response.status === 200 && response.data.status === "success") {
                        setCurrentVideo(response.data.data);
                        setCurrentVideoUrl(response.data.url);
                        if (videoRef.current) {
                            videoRef.current.src = response.data.url;
                            videoRef.current.load();
                        }
                    } else {
                        console.log("Error: ", response.data.message);
                    }                       
                })
                .catch(function (error) {
                    console.log(error);
                });
            } catch (err) {
                console.log(err);
            }
        }
        
    },[]);
    
    const handleSendEdit=()=>{
        navigate("/video/edit/media", { state: { video_id: VideoId} });
    }

    const downloadVideo = async () => {
        try {
          // Fetch the video from the URL
          const response = await fetch(currentVideoUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          // Convert the video data to a blob
          const blob = await response.blob();    
          // Create a link and use it to download the video
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = currentVideo.video_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading the video:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <Box sx={{ height: "90vh" }}>
                <Container
                    maxWidth="md"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        height: "100%",
                        flexDirection: "column",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid xs={6} md={6.5}>
                            <Item>
                                <Box
                                    sx={{
                                        background: "#110F2D",
                                        border: "0.5px solid #BF9BFF40",
                                        borderRadius: "11px",
                                        padding: "12px 17px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "15px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            color: "white",
                                            opacity: "40%",
                                            borderRight: "1px solid #C6B6E2",
                                            pr: "13px",
                                        }}
                                    >
                                        Title
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "18px",
                                            lineHeight: "23px",
                                            fontWeight: 500,
                                            color: "white",
                                        }}
                                    >
                                        {formData?.prompt ? formData.prompt : currentVideo.prompt??"" }
                                    </Typography>
                                    <ChevronRightIcon sx={{ color: "#FFFFFF", opacity: "40%", fontSize: "22px" }} />
                                </Box>
                            </Item>
                        </Grid>
                        <Grid xs={6} md={5.5}>
                            <Item sx={{ display: "flex", justifyContent: "end" }}>
                                <Item sx={{ mr: "11px" }}>
                                    <Button
                                        sx={{
                                            background: "#110F2D",
                                            padding: "14px 14px 14px 19px",
                                            borderRadius: "53px 0px 0px 53px",
                                            border: "0.5px solid #BF9BFF40",
                                            textTransform: "none",
                                            "&:hover": {
                                                border: "0.5px solid #BF9BFF40",
                                                background: "#110F2D",
                                            },
                                        }}
                                        variant="outlined"
                                    >
                                        <WestIcon
                                            sx={{
                                                fontSize: "25px",
                                                color: "white",
                                                opacity: "60%",
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        sx={{
                                            background: "#110F2D",
                                            padding: "14px 19px 14px 14px",
                                            borderRadius: "0px 53px 53px 0px",
                                            border: "0.5px solid #BF9BFF40",
                                            borderLeft: "none",
                                            textTransform: "none",
                                            "&:hover": {
                                                border: "0.5px solid #BF9BFF40",
                                                background: "#110F2D",
                                                borderLeft: "none",
                                            },
                                        }}
                                        variant="outlined"
                                    >
                                        <EastIcon
                                            sx={{
                                                fontSize: "25px",
                                                color: "white",
                                                opacity: "60%",
                                            }}
                                        />
                                    </Button>
                                </Item>
                                <Button
                                    sx={{
                                        background: "#110F2D",
                                        padding: "14px 14px 14px 19px",
                                        borderRadius: "53px 0px 0px 53px",
                                        border: "0.5px solid #BF9BFF40",
                                        textTransform: "none",
                                        "&:hover": {
                                            border: "0.5px solid #BF9BFF40",
                                            background: "#110F2D",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    <CachedIcon sx={{ fontSize: "25px", color: "white", opacity: "60%" }} />
                                </Button>
                                <Button
                                    sx={{
                                        background: "#110F2D",
                                        padding: "14px 19px 14px 14px",
                                        borderRadius: "0px 53px 53px 0px",
                                        border: "0.5px solid #BF9BFF40",
                                        borderLeft: "none",
                                        textTransform: "none",
                                        "&:hover": {
                                            border: "0.5px solid #BF9BFF40",
                                            background: "#110F2D",
                                            borderLeft: "none",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    <MoreHorizIcon sx={{ fontSize: "25px", color: "white", opacity: "60%" }} />
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            mt: "16px",
                            width: "100%",
                        }}
                    >
                        <video ref={videoRef} controls width="100%" height="489" controlsList="nodownload noplaybackrate" disablePictureInPicture>
                            <source src={responseData.url??responseData.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                    <Grid sx={{ mt: "16px" }} container spacing={0}>
                        <Grid xs={6} md={6}>
                            <Item sx={{ display: "flex" }}>
                                <Button
                                    sx={{
                                        background: "#110F2D",
                                        padding: "14px 14px 14px 19px",
                                        borderRadius: "53px 0px 0px 53px",
                                        border: "0.5px solid #BF9BFF40",
                                        textTransform: "none",
                                        "&:hover": {
                                            border: "0.5px solid #BF9BFF40",
                                            background: "#110F2D",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    <FavoriteBorderIcon sx={{ fontSize: "25px", color: "white", opacity: "60%" }} />
                                </Button>
                                <Button
                                    sx={{
                                        background: "#110F2D",
                                        padding: "14px 19px 14px 14px",
                                        borderRadius: "0px 53px 53px 0px",
                                        border: "0.5px solid #BF9BFF40",
                                        borderLeft: "none",
                                        textTransform: "none",
                                        "&:hover": {
                                            border: "0.5px solid #BF9BFF40",
                                            background: "#110F2D",
                                            borderLeft: "none",
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={downloadVideo}
                                >
                                    <SaveAltIcon sx={{ fontSize: "25px", color: "white", opacity: "60%" }} />
                                </Button>
                            </Item>
                        </Grid>
                        <Grid xs={6} md={6}>
                            <Item sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                                
                                    <Button
                                        sx={{
                                            background: "#110F2D",
                                            fontSize: "18px",
                                            lineHeight: "23px",
                                            fontWeight: 500,
                                            padding: "15px 24px",
                                            borderRadius: "53px",
                                            border: "0.5px solid #BF9BFF40",
                                            color: "#ffffff",
                                            textTransform: "none",
                                            "&:hover": {
                                                border: "0.5px solid #BF9BFF40",
                                                // background: "#363636",
                                            },
                                        }}
                                        onClick={()=>handleSendEdit()}
                                        variant="outlined"
                                    >
                                        <EditCalendarIcon sx={{ fontSize: "25px", color: "white", mr: "14px" }} />
                                        Edit{" "}
                                    </Button>
                                
                                <Button
                                    sx={{
                                        background: "#110F2D",
                                        fontSize: "18px",
                                        lineHeight: "23px",
                                        fontWeight: 500,
                                        padding: "12px 22px",
                                        borderRadius: "53px",
                                        border: "0.5px solid #BF9BFF40",
                                        color: "#FFFFFF",
                                        textTransform: "none",
                                        "&:hover": {
                                            border: "0.5px solid #BF9BFF40",
                                            // background: "#363636",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    <LogoutIcon sx={{ fontSize: "25px", color: "white", mr: "14px" }} />
                                    Publish{" "}
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>

                    <Grid sx={{ mt: "63px", width: "100%" }}>
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: "18px",
                                fontWeight: 700,
                                lineHeight: "23px",
                                color: "white",
                                ml: "18px",
                            }}
                        >
                            Edit the video using text commands below:
                        </Typography>
                        <Item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                // 								border: "2px solid",

                                // borderImageSource: "linear-gradient(146.26deg, #5131CE 5.05%, #FFA228 91.38%)",
                                p: "4px 4px 4px 16px",
                                background: "#141338",
                                width: "100%",
                                mt: "21px",
                                borderRadius: "164px",
                            }}
                        >
                            <ThemeProvider theme={customTheme(outerTheme)}>
                                <TextField sx={{ width: "70%" }} placeholder="Example: Delete the first frame, add captions" />
                            </ThemeProvider>
                            <Button
                                className="nav-button"
                                variant="outlined"
                                sx={{
                                    marginLeft: "auto",
                                    color: "white",
                                    border: "1px solid #878FA780",
                                    display: "flex",
                                    gap: "4px",
                                    padding: "13px 32px",
                                    borderRadius: "100px",
                                    textTransform: "none",
                                    fontSize: "18px",
                                    lineHeight: "23px",
                                    fontWeight: 700,
                                    boxShadow: "0px -7px 16.8px 0px #B04204 inset",
                                    "&:hover": {
                                        border: "1px solid #878FA780",
                                    },
                                }}
                            >
                                Generate <AutoAwesomeIcon sx={{ width: "20px", height: "20px" }} />
                            </Button>
                        </Item>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default Index;
