import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

const Item = styled(Paper)(({ theme }) => ({
  background: "none",
  boxShadow: "unset",
}));

const PromptIdeas = ({ onClose }) => {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const buttonStyles = (buttonIndex) => ({
    background:
      selectedButton === buttonIndex
        ? " linear-gradient(0deg, #763CDE, #763CDE), linear-gradient(355.83deg, #BF710B 39.23%, #FFA228 95.93%)"
        : "#201A46",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: 400,
    padding: "12px 22px",
    borderRadius: "53px",
    border: "0.5px solid #BF9BFF80",
    color: "#E4DEFF",
    textTransform: "none",
    width: "100%",
    justifyContent: "start",
    "&:hover": {
      border: "0.5px solid #BF9BFF80",
      // background: "#363636",
    },
    marginTop: buttonIndex === 1 ? "15px" : "0",
  });



  const [selectedSound, setSelectedSound] = useState(false);

  const handleClickSound = () => {
    setSelectedSound(!selectedSound);
  };

  const buttonStyleSound = {
    background: selectedSound
      ? "linear-gradient(0deg, #763CDE, #763CDE), linear-gradient(355.83deg, #BF710B 39.23%, #FFA228 95.93%)"
      : "#201A46",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: 400,
    padding: "12px 22px",
    borderRadius: "53px",
    border: "0.5px solid #BF9BFF80",
    color: "#E4DEFF",
    textTransform: "none",
    width: "100%",
    justifyContent: "start",
    "&:hover": {
      border: "0.5px solid #BF9BFF80",
      // background: "#363636",
    },
    marginTop: "19px",
  };

  const [selectedimage, setSelectedimage] = useState(false);

  const handleClickimage = () => {
    setSelectedimage(!selectedimage);
  };

  const buttonStyleimage = {
    background: selectedimage
      ? "linear-gradient(0deg, #763CDE, #763CDE), linear-gradient(355.83deg, #BF710B 39.23%, #FFA228 95.93%)"
      : "#201A46",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: 400,
    padding: "12px 22px",
    borderRadius: "53px",
    border: "0.5px solid #BF9BFF80",
    color: "#E4DEFF",
    textTransform: "none",
    width: "100%",
    justifyContent: "start",
    "&:hover": {
      border: "0.5px solid #BF9BFF80",
      // background: "#363636",
    },
    marginTop: "19px",
  };
  return (
    <Box className="prompt-box"
      style={{
        border: "2px solid",
        borderImageSource:
          "linear-gradient(146.26deg, #5131CE 5.05%, #FFA228 91.38%)",
        borderImageSlice: 1, // Ensures the border image is properly applied
        borderRadius: "16px", // Adds rounded corners to the box
        padding: "35px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "white",
          }}
        >
          Sample video prompt
        </Typography>
        <Item
          onClick={onClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            background: "#201A46",
            border: "0.5px solid #BF9BFF40",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
          }}
        >
          <CloseIcon sx={{ color: "white", fontSize: "20px" }} />
        </Item>
      </Grid>
      <Grid sx={{ mt: "27px" }}>
        <Button
          sx={buttonStyles(0)}
          variant="outlined"
          onClick={() => handleClick(0)}
        >
          A short video presenting 5 best destination in Thailand
        </Button>
        <Button
          sx={buttonStyles(1)}
          variant="outlined"
          onClick={() => handleClick(1)}
        >
          A short video presenting 5 best destination in Thailand
        </Button>
      </Grid>

      <Grid container sx={{ mt: "34px" }} spacing={0}>
        <Grid sx={{ p: "10px" }} xs={12} md={12}>
          <Item>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                color: "white",
              }}
            >
              Sample sound effect prompt
            </Typography>
            <Button
              sx={buttonStyleSound}
              variant="outlined"
              onClick={handleClickSound}
            >
              Create a creepy video of the 3 most haunted ho...
            </Button>
          </Item>
        </Grid>
      </Grid>

      <Item sx={{ mt: "27px" }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "white",
          }}
        >
          Sample image prompt
        </Typography>
        <Button
          sx={buttonStyleimage}
          variant="outlined"
          onClick={handleClickimage}
        >
          Create a creepy video of the 3 most haunted ho...
        </Button>
      </Item>
    </Box>
  );
};

export default PromptIdeas;
