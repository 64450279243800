import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FbDummy from "../../assests/Home/DummyImgFb.png";
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  background:"none", boxShadow:"unset"
}));

const Advertisement = () => {
  return (
    <div>
       <Item>
          <Item sx={{padding:"20px 30px", background:"#141338", borderRadius:"20px"}}>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Contest
            </Typography>
            <Item sx={{mt:"13px", padding:"0px 30px", textAlign:"center"}}>
            <img
              alt="Icon"
              src={FbDummy}
              style={{
                width: "auto",
                height: "415px",
                // width: "100%",
                // height:"100%"
              }}
            />
            </Item>
          </Item>
          <Item sx={{padding:"20px 30px", background:"#141338", borderRadius:"20px", mt:"24px", height:"245px "}}>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Notifications
            </Typography>
            <Item sx={{mt:"13px", padding:"0px 30px"}}>
          
            </Item>
          </Item>
          </Item>
    </div>
  )
}

export default Advertisement
